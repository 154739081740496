import React, {useState} from "react"
import ReactPaginate from "react-paginate"
import {GrFormPrevious, GrFormNext} from "react-icons/gr"

const Pagination = ({totalTabs, tabsPerPage, className, handleChange}) => {
  const [currentPage, setCurrentPage] = useState(0)

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected)
    handleChange(selectedPage.selected + 1)
  }

  const totalPages = Math.ceil(totalTabs / tabsPerPage)

  return (
    <div
      className={`pagination-container items-center justify-center my-2 ${className}`}>
      <ReactPaginate
        pageCount={totalPages}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        previousLabel={<GrFormPrevious className="size-[20px]" />}
        nextLabel={<GrFormNext className="size-[20px]" />}
        breakLabel={"..."}
        onPageChange={handlePageClick}
        containerClassName={"flex items-center gap-2 text-white"}
        activeClassName={
          "bg-[#fff] text-[#000] py-[4px] px-[10px] rounded-[10px] "
        }
      />
    </div>
  )
}

export default Pagination
