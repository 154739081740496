export const config = {
    saleAddress: "0xa110BE4FebcE78C89Ba76c9BD8Dc95821949B548",
    defaultReferral: "0x5C7F15502bbc3FF2aE5E6998aCe46c90c4d53768",
    price: 300,
    stakingAddress: "0xa110BE4FebcE78C89Ba76c9BD8Dc95821949B548",
    InfuraUrl: "https://rpc.pulsechain.com/",
    tokens: {
        USDT: {
            address: "0xF052BdA1AAf96deE984BaBc10e9f3424930f2E9c",
            decimal: 6,
        },
        USDC: {
            address: "0xF052BdA1AAf96deE984BaBc10e9f3424930f2E9c",
            decimal: 6,
        },
        DAI: {
            address: "0xF052BdA1AAf96deE984BaBc10e9f3424930f2E9c",
            decimal: 6,
        },
        PE: {
            address: "0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197",
            decimal: 18,
        },
        PELP: {
            address: "0x1515d879556ae70a76154b505804f9a29afb4cfd",
            decimal: 18,
        },
    },
    stakingTokens: {
        PE: {
            address: "0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197",
            priceAddress: "0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197",
            stakeAddress: "0xE881e99623787680DbbC0ecc65000a7D4E859514",
            decimal: 18,
            income: 1,
            type: "TOKEN",
            BuyLink:
                "https://app.pulsex.com/swap?outputCurrency=0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197",
        },
        PELP: {
            address: "0x1515d879556ae70a76154b505804f9a29afb4cfd",
            priceAddress: "0x1515d879556ae70a76154b505804f9a29afb4cfd",
            stakeAddress: "0x8B2151955f60Bf30c6bCd17ee9a5D30b04c48b2B",
            decimal: 18,
            income: 0.5,
            type: "LP",
            BuyLink:
                "https://v2-app.pulsex.com/add/0xdcDCF68b5B1Dc051c2237b0C92cB7df000FD9197/0xF052BdA1AAf96deE984BaBc10e9f3424930f2E9c",
        },
    },
};
