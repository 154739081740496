import "./App.css"
import {HashRouter as Router, Routes, Route} from "react-router-dom"
import MainPage from "./Pages/MainPage"
import Referral from "./Pages/Referral"
import Admin from "./admin"
import Profile from "./Pages/Profile"
import {useSelector} from "react-redux"

function App() {
  const {loggedIn} = useSelector((state) => state.user)
  return (
    <div className="App bg-[#010101] w-[100vw] overflow-x-hidden h-full min-h-[100vh]">
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/portal" element={<Admin />} />
          {loggedIn && <Route path="/profile" element={<Profile />} />}
        </Routes>
      </Router>
    </div>
  )
}

export default App
