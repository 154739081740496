import {createStore, compose} from "redux"
import {composeWithDevTools} from "@redux-devtools/extension"
import rootReducer from "./rootReducer"
import {persistStore, persistReducer} from "redux-persist"
import storage from "redux-persist/lib/storage"

const reduxDevTool =
  process.env.NODE_ENV === "development" ? composeWithDevTools() : compose()
const persistConfig = {
  key: "root",
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(persistedReducer, reduxDevTool)
export const persistor = persistStore(store)

export default store
