import { ethers } from "ethers";
import { ABI_PRE_SALE } from "./contracts/saleABI";
import { config } from "../config";

export const getStatus = (saleAddress, signer, address) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.totalEarnedTokens(address);
        const formattedData = ethers.utils.formatUnits(data, 18);

        resolve(formattedData);
    });
};

export const getTotalInvested = (saleAddress, signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.totalInvested();
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const hardCapValue = (saleAddress, signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.hardcap();
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const addressBalance = (saleAddress, signer) => {
    return new Promise(async (resolve, reject) => {
        const provider = signer.provider;
        const data = await provider.getBalance(saleAddress);
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const userClaimableToken = (saleAddress, signer, address) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.depositedTokens(address);
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const getClubSize = (address, saleAddress, signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.userReferralClubsize(address);
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const getReferral = (address, clubSize, saleAddress, signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.getMyDirectDownline(address, clubSize);
        // const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(data);
    });
};

export const depositTokenFun = (
    referralAddress,
    saleAddress,
    signer,
    amount,
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = new ethers.Contract(
                saleAddress,
                ABI_PRE_SALE,
                signer,
            );
            const value = ethers.utils.parseEther(`${amount}`);
            let result;
            await contract
                .deposit(referralAddress, { value: value })
                .then((r) => {
                    result = r;
                    console.log("%c Line:62 🍔 r", "color:#4fff4B", result);
                })
                .catch((err) => {
                    console.log("%c Line:67 🌶 err", "color:#e41a6a", err);
                });
            await result.wait();
            resolve(result);
        } catch (error) {
            console.log("%c Line:62 🍬 error", "color:#465975", error);
            resolve(false);
        }
    });
};

export const checkIsWhitelisted = (saleAddress, signer, userAddress) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.isWhitelisted(userAddress);
        resolve(data);
    });
};

export const checkIsPublic = (saleAddress, signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.isPublic();
        resolve(data);
    });
};
