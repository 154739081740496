import actions from "./actions"

const initialState = {
  user: null,
  loggedIn: false,
  loading: false,
  token: null,
  login: false,
  adminLogin: true,
  register: false,
}

const {
  SET_USER,
  SET_LOADING,
  SET_LOGGEDIN,
  SET_TOKEN,
  SET_REGISTER,
  SET_LOGIN,
  SET_ADMIN_LOGIN,
} = actions
const UserReducer = (state = initialState, action) => {
  const {type, data} = action
  switch (type) {
    case SET_USER:
      return {
        ...state,
        user: data,
      }
    case SET_TOKEN:
      return {
        ...state,
        token: data,
      }
    case SET_LOGIN:
      return {
        ...state,
        login: data,
      }
    case SET_ADMIN_LOGIN:
      return {
        ...state,
        adminLogin: data,
      }
    case SET_REGISTER:
      return {
        ...state,
        register: data,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      }
    case SET_LOGGEDIN:
      return {
        ...state,
        loggedIn: data,
      }

    default:
      return state
  }
}

export default UserReducer
