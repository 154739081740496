import React from "react"
import logo from "../Assets/Images/Logo.svg"
import {
  FaDiscord,
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaTelegramPlane,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa"

const Footer = () => {
  return (
    <div className="w-full mx-auto sm:w-[80%] flex flex-col items-center justify-center">
      <div className="flex flex-col sm:flex-row items-center justify-between p-2 w-full mx-auto sm:w-[80vw] border-b-2 border-[#67666e]">
        <img alt="" src={logo} />
        <div className="flex items-center justify-center gap-4 p-2">
          <FaTwitter
            onClick={() =>
              window.open("https://twitter.com/_MaticPlus", "_blank")
            }
            className="fill-white opacity-60 cursor-pointer size-[20px] hover:opacity-100"
          />
          <FaTelegramPlane
            onClick={() => window.open("https://t.me/matic_plus", "_blank")}
            className="fill-white opacity-60 cursor-pointer size-[20px] hover:opacity-100"
          />
          {/* <FaDiscord className="fill-white opacity-60 cursor-pointer size-[20px] hover:opacity-100" />
          <FaGithub className="fill-white opacity-60 cursor-pointer size-[20px] hover:opacity-100" />*/}
          <FaInstagram 
              onClick={() => window.open("https://www.instagram.com/matic.plus/", "_blank")}
              className="fill-white opacity-60 cursor-pointer size-[20px] hover:opacity-100" />
          <FaFacebook 
              onClick={() => window.open("https://www.facebook.com/people/Matic-Plus/61555903619859/", "_blank")}  
              className="fill-white opacity-60 cursor-pointer size-[20px] hover:opacity-100" /> 
          {/* <FaLinkedin className="fill-white opacity-60 cursor-pointer size-[20px] hover:opacity-100" />
          <FaYoutube className="fill-white opacity-60 cursor-pointer size-[20px] hover:opacity-100" /> */}
        </div>
      </div>
      <div className="text-white opacity-60 text-[16px] text-left w-full p-2 ">
        © 2024 | All rights reserved
      </div>
    </div>
  )
}

export default Footer
