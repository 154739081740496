const actions = {
  SET_USER: "SET_USER",
  SET_LOADING: "SET_LOADING",
  SET_TOKEN: "SET_TOKEN",
  SET_LOGIN: "SET_LOGIN",
  SET_ADMIN_LOGIN: "SET_ADMIN_LOGIN",
  SET_REGISTER: "SET_REGISTER",
  SET_LOGGEDIN: "SET_LOGGEDIN",
  setUser: (data) => {
    return {
      type: actions.SET_USER,
      data,
    }
  },
  setToken: (data) => {
    return {
      type: actions.SET_TOKEN,
      data,
    }
  },
  setLogin: (data) => {
    return {
      type: actions.SET_LOGIN,
      data,
    }
  },
  setAdminLogin: (data) => {
    return {
      type: actions.SET_ADMIN_LOGIN,
      data,
    }
  },
  setRegister: (data) => {
    return {
      type: actions.SET_REGISTER,
      data,
    }
  },
  setLoading: (data) => {
    return {
      type: actions.SET_LOADING,
      data,
    }
  },
  setLoggedIn: (data) => {
    return {
      type: actions.SET_LOGGEDIN,
      data,
    }
  },
}

export default actions
