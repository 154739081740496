import request from "./request"

export const getUser = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/user/auth/login", data)
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const createUser = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/user/auth/register", data)
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const userList = ({params, headers}) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/admin/user/list", {
        params: params,
        headers: headers,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const generateOtp = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("/user/auth/send-otp", body)
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const verifyOtp = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/user/auth/forgot/password/verify", body)
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const forgotPassword = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/user/auth/forgot/password", body)
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const getUserData = (walletAddress) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/admin/user/wallet-user-name?walletAddress=${walletAddress}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const addReferal = ({body, headers}) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`/admin/user/refferal`, {
        body: body,
        headers: headers,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const updateUser = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/user/auth/update/${id}`, body)
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const updatePassword = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/user/auth/password`, body)
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
