import React, {useEffect, useState} from "react"
import AdminLogin from "./AdminLogin"
import DataTable from "react-data-table-component"
import {userList} from "../service/api"
import {useSelector} from "react-redux"
import Pagination from "./Pagination"
import Navbar from "../Pages/Navbar"
import {useNavigate} from "react-router"
import moment from "moment/moment"

const Admin = () => {
  const navigate = useNavigate()
  const [userData, setUserData] = useState([])
  console.log("%c Line:9 🥑 userData", "color:#ffdd4d", userData)
  const user = useSelector((state) => state.user)
  const [page, setPage] = useState(1)
  const [tab, setTab] = useState("total")
  const handleChange = (e) => setPage(e)
  //   console.log("%c Line:10 🍣 user", "color:#4fff4B", user)
  const getUser = () => {
    const today = new Date()
    const next = new Date(today)
    next.setDate(today.getDate() + 1)

    let params = {page: page, sizePerPage: "12"}

    if (tab === "daily") {
      params.startDate = moment(today).format("YYYY-MM-DD")
      params.endDate = moment(next).format("YYYY-MM-DD")
    }
    if (tab === "active") {
      params.isDeposited = true
    }
    if (tab === "inactive") {
      params.isDeposited = false
    }
    userList({
      params: params,
      headers: {Authorization: user?.token},
    })
      .then((res) => {
        console.log("%c Line:10 🥤 res", "color:#b03734", res)
        if (res?.status) {
          setUserData(res?.data)
        }
      })
      .catch((e) => {
        console.log("%c Line:12 🍆 e", "color:#ed9ec7", e)
      })
  }

  useEffect(() => {
    getUser()
  }, [page, tab])

  const data = [
    {
      name: "name",
      userName: "hcdsuj",
      email: "email",
      mobile: "csdvcs",
      walletAddress: "0cds4 1xz",
    },
  ]
  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <span className="text-[14px] text-[#7f8483] ">{row?.name}</span>
      ),
    },
    {
      name: "User Name",
      selector: (row) => (
        <span className="text-[14px] text-[#7f8483] ">{row?.userName}</span>
      ),
    },
    {
      name: "Email",
      selector: (row) => (
        <span className="text-[14px] text-[#7f8483] ">{row?.email}</span>
      ),
    },
    {
      name: "Country",
      selector: (row) => (
        <span className="text-[14px] text-[#7f8483] ">{row?.mobile}</span>
      ),
    },
    {
      name: "Created at",
      selector: (row) => (
        <span className="text-[14px] text-[#7f8483] ">
          {moment(row?.createdAt).format("DD/MM/YYYY HH:MM:SS")}
        </span>
      ),
    },
    {
      name: "Wallet Address",
      selector: (row) => (
        <span
          className="text-[14px] text-[#7f8483] "
          onClick={() => navigate(`/referral?address=${row?.walletAddress}`)}>
          {row?.walletAddress}
        </span>
      ),
    },
  ]
  return (
    <>
      <Navbar />
      <AdminLogin />
      <div>
        <div className="flex justify-center items-center mb-4 border-b-2 border-black p-2 ">
          <p className="text-[18px] text-white sm:text-[20px] font-semibold">
            Total members with details
          </p>
        </div>
        <div className="flex w-[95vw] sm:w-fit  p-1 mb-2 gap-1 sm:gap-2 items-center justify-center mx-auto bg-white rounded-[10px]">
          <p
            className={`${
              tab === "total"
                ? "bg-[#6e38cc] text-white"
                : "bg-transparent text-black"
            } rounded-[8px] p-2 cursor-pointer`}
            onClick={() => setTab("total")}>
            Total Team
          </p>
          <p
            className={`${
              tab === "daily"
                ? "bg-[#6e38cc] text-white"
                : "bg-transparent text-black"
            } rounded-[8px] p-2 cursor-pointer`}
            onClick={() => setTab("daily")}>
            Daily New Joining
          </p>
          <p
            className={`${
              tab === "active"
                ? "bg-[#6e38cc] text-white"
                : "bg-transparent text-black"
            } rounded-[8px] p-2 cursor-pointer`}
            onClick={() => setTab("active")}>
            Active Team
          </p>
          <p
            className={`${
              tab === "inactive"
                ? "bg-[#6e38cc] text-white"
                : "bg-transparent text-black"
            } rounded-[8px] p-2 cursor-pointer`}
            onClick={() => setTab("inactive")}>
            Inactive Team
          </p>
        </div>
        <div
          className={`${
            tab === "daily" || tab === "active" ? "flex" : "hidden"
          } flex-col sm:flex-row items-center justify-center w-full gap-y-2 p-2 my-2`}>
          <p className="text-[16px] text-white sm:text-[18px] font-semibold">
            {tab === "daily" ? "Daily" : "Total"} deposited matic coin :{" "}
            {userData && userData?.docs?.length * 30}
          </p>{" "}
          {/* <p className="text-[16px] text-white sm:text-[18px] font-semibold">
            Daily withdrawal matic coin : 0
          </p> */}
        </div>
        <div className="overflow-hidden overflow-x-scroll max-w-[2650px] mb-4 mx-3 sm:mx-5 bg-[#ffffff] rounded-[15px]">
          <DataTable
            columns={columns}
            data={userData ? userData?.docs : []}
            className=" w-full table"
            responsive
            noDataComponent={
              <div className="w-full flex flex-col items-center justify-center gap-5 bg-[#ffffff] py-5">
                <p className="text-[16px] text-[#000] opacity-60 font-medium text-center">
                  No User found.
                </p>
              </div>
            }
          />
        </div>
        <Pagination
          totalTabs={userData?.totalDocs}
          tabsPerPage={12}
          handleChange={handleChange}
          className={`${userData?.totalPages > 1 ? "flex" : "hidden"} w-full `}
        />
      </div>
    </>
  )
}

export default Admin
