import {Spinner} from "@material-tailwind/react"
import {useAddress, useSigner} from "@thirdweb-dev/react-core"
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useNavigate} from "react-router"
import {Button} from "react-scroll"
import actions from "../redux/user/actions"
import {IoMdEye, IoMdEyeOff} from "react-icons/io"
import {FaArrowAltCircleLeft} from "react-icons/fa"
import {updatePassword, updateUser} from "../service/api"
import {toast} from "react-toastify"
import {config} from "../config"
import {getStatus} from "../utility/sale"

const Profile = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  //   console.log("%c Line:14 🍡 user", "color:#ea7e5c", user)
  const address = useAddress()
  const [oldPassword, setOldPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [payload, setPayload] = useState({
    name: "",
    userName: "",
    country: "",
    email: "",
    // password: "",
    walletAddress: "",
  })
  const [payloadErr, setPayloadErr] = useState({
    name: false,
    userName: false,
    country: false,
    email: false,
    // password: false,
    walletAddress: false,
  })
  const [tab, setTab] = useState("profile")
  const [loading, setLoading] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false)
  const [passwordShowNew, setPasswordShowNew] = useState(false)
  const handleChange = (e) => {
    e.preventDefault()
    setPayload({...payload, [e.target.name]: e.target.value})
    setPayloadErr({...payloadErr, [e.target.name]: false})
  }
  useEffect(() => {
    if (user) {
      setPayload({
        ...payload,
        name: user?.user?.name,
        userName: user?.user?.userName,
        email: user?.user?.email,
        walletAddress: user?.user?.walletAddress,
        country: user?.user?.mobile,
      })
    }
  }, [user])

  const handlePassword = () => {
    if (!oldPassword) {
      return toast.error("Enter Old password")
    }
    if (!newPassword) {
      return toast.error("Enter New password")
    }
    if (oldPassword === newPassword) {
      return toast.error("New password can't same as old password")
    }
    setLoading(true)

    updatePassword({oldPassword: oldPassword, password: newPassword})
      .then((res) => {
        console.log("%c Line:59 🍎 res", "color:#93c0a4", res)
        if (res?.status) {
          toast.success(res?.message)
          setNewPassword()
          setOldPassword()
          setLoading(false)
        } else {
          toast.error(res?.message)
          setLoading(false)
        }
      })
      .catch((e) => {
        console.log("%c Line:62 🥛 e", "color:#3f7cff", e)
        setLoading(false)
        toast.error(e?.response?.data?.message)
      })
  }

  const handleUpdate = () => {
    for (let key in payload) {
      if (payload[key] === null || payload[key] === "") {
        setPayloadErr((prevState) => ({...prevState, [key]: true}))
        toast.error(`${key} is required`)
      }
    }
    if (
      payload?.email === "" ||
      payload?.name === "" ||
      payload?.userName === "" ||
      // payload?.password === "" ||
      payload?.country === "" ||
      payload?.walletAddress === ""
    ) {
      return
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!emailRegex.test(payload?.email)) {
      toast.error("Please Enter Valid Email")
      return
    }
    setLoading(true)
    let body = {
      name: payload?.name,
      userName: payload?.userName,
      email: payload?.email,
      mobile: payload?.country,
    }
    updateUser(user?.user?._id, body)
      .then((res) => {
        console.log("%c Line:40 🍞 res", "color:#3f7cff", res)
        if (res?.status) {
          setPayload({
            name: "",
            userName: "",
            mobile: "",
            email: "",
            password: "",
            walletAddress: "",
          })
          dispatch(actions.setUser(res?.data))
        }
        toast.success(res?.message)
        setLoading(false)
      })
      .catch((e) => {
        console.log("%c Line:42 🥤 e", "color:#f5ce50", e)
        setLoading(false)
        toast.error(e?.response?.data?.message)
      })
  }

  const [coinIncome, setCoinIncome] = useState("")
  // const address = useAddress()
  const signer = useSigner()
  useEffect(() => {
    if (!address || !signer) return
    const getData = async () => {
      let data = await getStatus(config.saleAddress, signer, address)
      console.log("%c Line:154 🍰 data", "color:#ffdd4d", data)
      setCoinIncome(data)
    }
    getData()
  }, [address, signer])

  return (
    <div className="w-full sm:w-[700px] mx-auto">
      <h3 className="text-[#fff] flex items-center justify-start gap-2 w-full px-4 sm:px-0 text-left font-medium text-[45px] sm:text-[60px]">
        <FaArrowAltCircleLeft
          className="cursor-pointer"
          onClick={() => navigate(-1)}
        />
        Profile
      </h3>
      <div className="flex w-fit p-1 my-2 gap-2 items-center justify-center mx-auto bg-white rounded-[10px]">
        <p
          className={`${
            tab === "profile"
              ? "bg-[#6e38cc] text-white"
              : "bg-transparent text-black"
          } rounded-[8px] p-2 cursor-pointer`}
          onClick={() => setTab("profile")}>
          Profile
        </p>
        <p
          className={`${
            tab === "password"
              ? "bg-[#6e38cc] text-white"
              : "bg-transparent text-black"
          } rounded-[8px] p-2 cursor-pointer`}
          onClick={() => setTab("password")}>
          Change password
        </p>
      </div>
      {tab === "profile" ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 items-start justify-center gap-3 px-3 sm:px-0 my-4 w-full">
          <div className="flex flex-col items-start justify-center gap-1 w-full">
            <p className="text-white text-[14px]">Name</p>
            <input
              placeholder="abc"
              type="text"
              className={`border ${
                payloadErr?.name ? "border-red-800" : "border-[#e5e5e5]"
              } outline-none text-black rounded-[10px] w-full p-2`}
              value={payload?.name}
              name="name"
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col items-start justify-center gap-1 w-full">
            <p className="text-white text-[14px]">Username</p>
            <input
              placeholder="Username"
              type="text"
              className={`border ${
                payloadErr?.userName ? "border-red-800" : "border-[#e5e5e5]"
              } outline-none text-black rounded-[10px] w-full p-2`}
              value={payload?.userName}
              name="userName"
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col items-start justify-center gap-1 w-full">
            <p className="text-white text-[14px]">Email</p>
            <input
              placeholder="Email"
              type="email"
              className={`border ${
                payloadErr?.email ? "border-red-800" : "border-[#e5e5e5]"
              } outline-none text-black rounded-[10px] w-full p-2 input`}
              value={payload?.email}
              name="email"
              readOnly
              // onChange={handleChange}
            />
          </div>
          <div className="flex flex-col items-start justify-center gap-1 w-full">
            <p className="text-white text-[14px]">Country</p>
            <input
              placeholder="Country"
              type="text"
              className={`border ${
                payloadErr?.country ? "border-red-800" : "border-[#e5e5e5]"
              } outline-none text-black rounded-[10px] w-full p-2`}
              value={payload?.country}
              name="country"
              onChange={handleChange}
            />
          </div>
          {/* <div className="flex flex-col items-start justify-center gap-1 w-full">
          <p className="text-white text-[14px]">Password</p>{" "}
          <div className="relative w-full">
            <input
              placeholder="******"
              type={passwordShow ? "text" : "password"}
              value={payload?.password}
              name="password"
              onChange={handleChange}
              className={`border ${
                payloadErr?.password ? "border-red-800" : "border-[#e5e5e5]"
              } outline-none text-black rounded-[10px] w-full p-2`}
            />{" "}
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-1 right-1"
              onClick={() => setPasswordShow(!passwordShow)}>
              {passwordShow ? (
                <IoMdEyeOff className="w-5 h-5 text-black" />
              ) : (
                <IoMdEye className="w-5 h-5 text-black" />
              )}
            </Button>
          </div>
        </div> */}
          <div className="flex flex-col items-start justify-center gap-1 w-full">
            <p className="text-white text-[14px]">Wallet address</p>
            <input
              placeholder="wallet address"
              type="text"
              className={`border ${
                payloadErr?.walletAddress
                  ? "border-red-800"
                  : "border-[#e5e5e5]"
              } outline-none text-black rounded-[10px] w-full p-2 input`}
              value={payload?.walletAddress}
              name="walletAddress"
              readOnly
              // onChange={handleChange}
            />
          </div>
          <div className="flex flex-col items-start justify-center gap-1 w-full">
            <p className="text-white text-[14px]">Matic coin income</p>
            <input
              placeholder="Matic income"
              type="text"
              className={`border  outline-none text-black rounded-[10px] w-full p-2`}
              value={coinIncome}
              name="walletAddress"
              readOnly
            />
          </div>
          {/* <div className="flex flex-col items-start justify-center gap-1 w-full">
                        <p className="text-white text-[14px]">
                            Royalty level income monthly
                        </p>
                        <input
                            placeholder="Royalty level income monthly"
                            type="text"
                            className={`border outline-none text-black rounded-[10px] w-full p-2`}
                            value={0}
                            name="walletAddress"
                            readOnly
                        />
                    </div> */}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 items-start justify-center gap-3 px-3 sm:px-0 my-4 w-full">
          <div className="flex flex-col items-start justify-center gap-1 w-full">
            <p className="text-white text-[14px]">Old Password</p>{" "}
            <div className="relative w-full">
              <input
                placeholder="******"
                type={passwordShow ? "text" : "password"}
                value={oldPassword}
                name="password"
                onChange={(e) => setOldPassword(e.target.value)}
                className={`border outline-none text-black rounded-[10px] w-full p-2`}
              />{" "}
              <Button
                className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-1 right-1"
                onClick={() => setPasswordShow(!passwordShow)}>
                {passwordShow ? (
                  <IoMdEyeOff className="w-5 h-5 text-black" />
                ) : (
                  <IoMdEye className="w-5 h-5 text-black" />
                )}
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-start justify-center gap-1 w-full">
            <p className="text-white text-[14px]">New Password</p>{" "}
            <div className="relative w-full">
              <input
                placeholder="******"
                type={passwordShowNew ? "text" : "password"}
                value={newPassword}
                name="password"
                onChange={(e) => setNewPassword(e.target.value)}
                className={`border outline-none text-black rounded-[10px] w-full p-2`}
              />{" "}
              <Button
                className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-1 right-1"
                onClick={() => setPasswordShowNew(!passwordShowNew)}>
                {passwordShowNew ? (
                  <IoMdEyeOff className="w-5 h-5 text-black" />
                ) : (
                  <IoMdEye className="w-5 h-5 text-black" />
                )}
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center flex-col sm:flex-row justify-center px-3 sm:px-0 gap-3 w-full my-4">
        {tab === "profile" ? (
          <Button
            className="w-full bg-[#8141ee] cursor-pointer p-2 rounded-[10px] text-white"
            onClick={handleUpdate}
            disabled={loading}>
            {loading ? (
              <Spinner className="text-center w-full " />
            ) : (
              "Update Profile"
            )}
          </Button>
        ) : (
          <Button
            className="w-full bg-[#8141ee] cursor-pointer p-2 rounded-[10px] text-white"
            onClick={handlePassword}
            disabled={loading}>
            {loading ? (
              <Spinner className="text-center w-full " />
            ) : (
              "Change Password"
            )}
          </Button>
        )}
        <Button
          className="w-full bg-[#8141ee] cursor-pointer p-2 rounded-[10px] text-white"
          onClick={() => {
            dispatch(actions.setLoggedIn(false))
            dispatch(actions.setToken(null))
            dispatch(actions.setUser(null))
            navigate("/")
          }}>
          Logout
        </Button>
      </div>
    </div>
  )
}

export default Profile
